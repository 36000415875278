import React from 'react';
import '../styles/text.css';
import allotmentsSatellite from '../assets/images/allotments_satellite.png';
import glasgowMap from '../assets/images/glasgow_map.png';
import actionDay1 from '../assets/images/action_day_1.jpeg';
import sunset1 from '../assets/images/sunset_1.jpeg';

export default function About() {
  return (
    <div className='about'>
      <h1>
        The Association
      </h1>
      <p>
        Hillend Allotments (formerly Lambhill Allotments) is an independent community of growers based in north-west Glasgow.  Our diverse membership numbers over sixty people who all work as a team to maintain the site, led by a volunteer committee.
      </p>
      <figure>
        <img className='about-img' src={actionDay1} alt='Action Day' width='60%'/>
        <figcaption>
          Plot holders relaxing with homemade cider after a community action day.
        </figcaption>
      </figure>
      <figure>
        <img className='about-img' src={sunset1} alt='Sunset Over The Plots' width='60%'/>
        <figcaption>
          Sunset and full moon over the plots.
        </figcaption>
      </figure>
      <hr />
      <h1>
        The Site
      </h1>
      <p>
        The allotments are situated between the areas of Lambhill and Parkhouse.&nbsp;&nbsp;The land they occupy is bordered to the south by the railway line, to the west by the Forth & Clyde canal, to the north by Lambhill, and to the east by Balmore Road.&nbsp;&nbsp;The site itself has an area of just under three acres and is host to over sixty plots and an orchard.
      </p>
      <figure>
        <img className='about-img' src={glasgowMap} alt='Glasgow Map' />
        <figcaption>
          The areas of Lambhill and Parkhouse in North Glasgow.  The red rectangle is expanded below, with the green rectangle representing the allotments site.
        </figcaption>
      </figure>
      <figure>
        <img className='about-img' src={allotmentsSatellite} alt='Satellite' width='60%' />
      </figure>
      <hr />
      <h1>
        History
      </h1>
      <p>
        Under construction....
      </p>
      
    </div>
  )
}
