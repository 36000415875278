import React from 'react'
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import Charity from './Charity';


export default function Home({ guest, guestLevel, signOut }) {

  return (
    <>
        <NavBar guest={guest} guestLevel={guestLevel} signOut={signOut} />
        <Outlet />
        <Charity />
    </>
  )
}
