import '../styles/charity.css';
import oscr from '../assets/images/oscr.png';

export default function Charity() {
    return (
        <div className="charity">
            <div className='centre'>
                <a href="https://www.oscr.org.uk/about-charities/search-the-register/charity-details?number=SC053757">
                    <img src={oscr} height="30px"/>
                </a>
            </div>
            <div className="centre">
                Hillend Allotments is a Scottish Charity, SC053757, regulated by the Scottish Charity Regulator (OSCR)
            </div>
        </div>
    );
}