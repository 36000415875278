import React from 'react';
import coverPhoto from '../assets/images/allotment_ally_1.jpg';

export default function HomeContent() {
  return (
    <div>
      <h1>Welcome to Hillend Allotments</h1>
      <figure>
        <img src={coverPhoto} alt='carrots' width='60%'/>
        <figcaption>Credit: Ally Smith</figcaption>
      </figure>
  </div>
  )
}
