// Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
// Utilities
import { navItems } from '../utilities/helper';
// Components
import sign from '../assets/images/Sign.png';
import BurgerMenu from './BurgerMenu';
// Styles
import '../styles/nav.css';


export default function NavBar({ guest, guestLevel, signOut }) {

    const normalMenu = navItems.filter(item => item.level === 0)
                .map((item, index) => (
                <NavLink to={item.link} key={index} className={ ({isActive}) => isActive ? 'selected' : 'link' }>
                    {item.title}
                </NavLink>
                )
            );

    const burgerItems = navItems.filter(item => item.level > 0 && item.level <= guestLevel)

  return (
    <nav className='strip'>
        <img src={sign} alt='Hillend Allotments' height='60px'/>
        <div className='nav-left'>
            <div>
                { normalMenu }
            </div>
        </div>
        <div className='nav-right'>
            {guestLevel ? <div className='burger-container'>
                <BurgerMenu menuItems={burgerItems} />
            </div> : null}
        </div>
    </nav>
  )
}
